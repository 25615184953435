<template>
  <div
    @click="openGoogleMaps"
    class="message__block message__block--map"
    role="button">
    <map-static-location
      :lat="lat"
      :lng="lng"/>
    <a
      ref="openAnchor"
      :href="googleMapsUrl"
      target="_blank"
      style="display: none;"></a>
    <div
      v-if="hasName"
      class="message__block-footer">
      <div class="message__block-footer-copy">{{ name }}</div>
    </div>
  </div>
</template>

<script>
import MapStaticLocation from '@/components/MapLocationStatic.vue';

export default {
  components: {
    MapStaticLocation,
  },
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      width: 300,
      height: 200,
      scale: 2,
      zoom: 15,
    };
  },
  computed: {
    location() {
      return this.message.location;
    },
    lat() {
      return this.location.lat;
    },
    lng() {
      return this.location.lng;
    },
    name() {
      return this.location.name;
    },
    hasName() {
      return !!this.name && this.name.length > 0;
    },
    googleMapsUrl() {
      return `${process.env.VUE_APP_GOOGLE_MAPS_BASE_URL}?api=1&map_action=map&center=${this.lat},${this.lng}`;
    },
  },
  methods: {
    openGoogleMaps() {
      this.$refs.openAnchor.click();
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_message.scss';
</style>
